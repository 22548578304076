import React from 'react';
import './styles/global.scss';
import './styles/reset.scss';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { createStore } from "redux";
import App from './App';
import { Provider } from 'react-redux';

const defaultState = {
  paymentPeriod: {}
}

const reducer = (state = defaultState, action) => {
  switch(action.type) {
    case 'SELECT_PERIOD':
      return {...state, paymentPeriod: action.payload}

    default:
      return state;
  }
}

const store = createStore(reducer);
const container = document.getElementById("root");
const root = createRoot(container);
const Root = () => {
    if (window.location.href.includes("/#/")) {
        window.history.replaceState({}, document.title, window.location.href.replace('/#/', '/'));
    }
    let l = window.location;
    let array = l.pathname.split('/');
    let basename
    if(array.indexOf("eu") > -1) {
        basename = 'eu';
    } else if(array.indexOf("us") > -1) {
        basename = 'us';
    } else if(array.indexOf("lam") > -1) {
        basename = 'lam';
    } else if(array.indexOf("bfa") > -1) {
        basename = 'bfa';
    } else if(array.indexOf("bf") > -1) {
        basename = 'bf';
    } else if(array.indexOf("ny") > -1) {
        basename = 'ny';
    } else if(array.indexOf("k") > -1) {
        basename = 'k';
    }
    if(!basename) {
        window.location.replace(l.href.replace(`${l.origin}`, `${l.origin}/eu`))
    }
    localStorage.setItem('location', basename)

    return (
        <BrowserRouter basename={`/${basename}`} >
            <Provider store={store}>
                <App />
            </Provider>
        </BrowserRouter>
    );
};

root.render(<Root />);
