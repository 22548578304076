import { useEffect } from "react";
import { useNavigate } from "react-router";

import ReactPixel from "react-facebook-pixel";
import amplitude from 'amplitude-js';
import moment from "moment/moment";
import sha256 from "crypto-js/sha256";

import ButtonCard from "../../components/UI/buttonCard/ButtonCard";

import data from "../../core/static/goal/mainGoal.json";
import useJsonParse from "../../hooks/useJsonParse";
import styles from "./Intro.module.scss";
import eventData from "../../core/static/eventData.json";

const token = 'EAArT7Rx5i30BAO9TAd7UXKoZAV2lxX2AE0ZC0o2H0hsH928qbp990KiB9HG1tpLNEkUSQ8ICx8UWvbHeeZCLIL5px8Y1la0TWZARRtLq6V0ERRq4tn25gcsHyZAFsuFeVA09uTXdANgoi70gk2h0oIK9zONzQFxPFLGjuGOh4pVAvIciJsmU3mag4gRfBfN0ZD';

const Intro = () => {
    const subdomain = localStorage.getItem('subdomain') || "a";
    const cards = useJsonParse(data.intro);
    const eventInfo = eventData[subdomain].intro;
    const navigate = useNavigate();

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_intro_appeared');
        localStorage.removeItem('discountStatus');
        const eventId = `viewcontent.${getCookie('id')}`;
        ReactPixel.track('ViewContent', {eventID: eventId});
        fetch(`https://graph.facebook.com/v10.0/239948538703054/events?access_token=${token}`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data: [
                    {
                        event_name: "ViewContent",
                        event_time: moment().unix(),
                        action_source: "website",
                        event_id: eventId,
                        user_data: {
                            client_user_agent: navigator.userAgent,
                            external_id: sha256(getCookie('id')).toString()
                        }
                    }
                ]
            })
        })
    }, [])

    function getCookie(name) {
        const matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    const selectCard = card => {
        localStorage.setItem('goal', card.title);
        navigate('steps');
        amplitude.getInstance().logEvent('button_onboarding_intro_tapped', { answer: card.title});
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <div className={`${styles.backgroundImageBox}`} style={eventInfo.backgroundStyle}>
                <img className={styles.backgroundImage} src={eventInfo.backgroundImage} alt="" />
            </div>
            <div className={`${styles.absoluteContainer} flex-column`}>
                <img className={styles.whiteImage} src="/images/intro/white-logo.png" alt="" />
                <span className={styles.prefix}>{eventInfo.prefix}</span>
                <h1>Join Your Dancing Weight Loss Challenge</h1>
                <span className={styles.subtitle}>To start please select your main goal:</span>
                <img className={styles.arrowDownImage} src="/images/intro/arrow-down.png" alt="" />
                <div className={`${styles.cardsContainer} flex-row`}>
                    { cards?.map(card => (
                        <ButtonCard key={card.id} card={card} selectCard={selectCard} />
                    )) }
                </div>
                <div className={`${styles.agreements} flex-column`}>
                    <span>By continuing you agree to</span>
                    <div className={`${styles.agreementLinks} flex-row`}>
                        <a href={'https://everdance.app/terms'}>Terms of Use</a>
                        <span className={styles.agreementInfo}>|</span>
                        <a  href={'https://everdance.app/policy'}>Privacy Policy</a>
                    </div>
                    <span>2023 © All rights reserved.</span>
                </div>
            </div>
        </div>
    )
}   

export default Intro;